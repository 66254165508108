<template>
  <div>
    <div v-for="(item, index) in blocks" :key="index">
      <v-row>
        <v-col cols="6">
          <v-menu
            v-model="item.startModal"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(item.check_in)"
                label="Период с"
                prepend-icon="mdi-calendar-blank-multiple"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              :first-day-of-week="1"
              class="mt-0 mb-0"
              v-model="item.check_in"
              @input="
                getCategories(item);
                item.startModal = false;
              "
            />
          </v-menu>
        </v-col>
        <v-col cols="6">
          <v-menu
            v-model="item.endModal"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(item.check_out)"
                label="По"
                prepend-icon="mdi-calendar-blank-multiple"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              :first-day-of-week="1"
              class="mt-0 mb-0"
              v-model="item.check_out"
              @input="
                getCategories(item);
                item.endModal = false;
              "
            />
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-2 pb-0" cols="6">
          <v-select
            dense
            label="Категории"
            v-model="item.category"
            :items="categories"
            item-text="name"
            item-value="id"
            @change="selectCategory(item)"
          />
        </v-col>
        <v-col class="pt-2 pb-0" cols="6">
          <v-select
            dense
            multiple
            label="Номера"
            v-model="item.rooms"
            :items="item.category_rooms"
            item-value="id"
            item-text="name"
          />
        </v-col>
      </v-row>
      <v-btn
        v-if="blocks.length > 1 || !$store.state.isBlockOpen"
        class="mb-5"
        tile
        outlined
        color="red lighten-1"
        @click="removeBlock(index)"
      >
        <v-icon left> mdi-minus </v-icon>
        Удалить блок
      </v-btn>
      <v-divider></v-divider>
    </div>
    <v-row class="pl-3 mt-5" v-if="!$store.state.isBlockOpen">
      <v-btn class="mb-5" tile outlined color="primary" @click="addBlock">
        <v-icon left> mdi-plus </v-icon>
        Добавить блок
      </v-btn>
      <v-btn
        class="mb-5 ml-3"
        tile
        outlined
        color="red lighten-1"
        @click="clearBlock"
      >
        <v-icon left> mdi-minus </v-icon>
        Очистить блоки
      </v-btn>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-btn
          color="primary"
          @click="saveBlocks(blocks)"
          :disabled="disabledBtn"
        >
          Сохранить
        </v-btn>
        <v-btn v-if="has_cancel" @click="cancel"> Отмена </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import { CategoriesApiUrls } from "@/services/accommodationRequests/categories.api.js";

export default {
  props: {
    disabledBtn: { type: Boolean, default: false },
    saveBlocks: { type: Function },
    cancel: { type: Function },
    has_cancel: { type: Boolean }
  },
  data() {
    return {
      categories: [],
      blocks: [
        {
          startModal: false,
          endModal: false,
          check_in: moment(new Date().getTime()).format("YYYY-MM-DD"),
          check_out: moment(new Date().getTime()).format("YYYY-MM-DD"),
          category: null,
          rooms: [],
          category_rooms: []
        }
      ]
    };
  },
  created() {
    this.getCategories(this.blocks[0]);
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    async getCategories(item) {
      item.category = null;
      item.rooms = [];
      this.categories = await CategoriesApiUrls.getCategories(item);
    },
    async selectCategory(item) {
      item.category_rooms = await CategoriesApiUrls.getSelectedRooms(
        item.category
      );
      item.room = null;
    },
    addBlock() {
      this.blocks.push({
        startModal: false,
        endModal: false,
        check_in: moment(new Date().getTime()).format("YYYY-MM-DD"),
        check_out: moment(new Date().getTime()).format("YYYY-MM-DD"),
        category: null,
        rooms: [],
        category_rooms: []
      });
    },
    clearBlock() {
      this.blocks = [
        {
          startModal: false,
          endModal: false,
          check_in: moment(new Date().getTime()).format("YYYY-MM-DD"),
          check_out: moment(new Date().getTime()).format("YYYY-MM-DD"),
          category: null,
          rooms: [],
          category_rooms: []
        }
      ];
    },
    removeBlock(index) {
      this.blocks.splice(index, 1);
    }
  }
};
</script>

<style></style>
